import { useState, useEffect, useRef } from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import useKeyPress from './hooks/useKeypress.js'
import CornerstoneViewer from './components/CornerstoneViewer.component.js'
// import Dropdown from 'react-bootstrap/Dropdown'

const getHeader = () => {
  return(
    <header className="header">
      <div>
        <h1 align="center" > Quick Image Segmenter </h1>
      </div>
    </header>
  )
}

const getInstructions = () => {
  return(
    <div className="body-container-instructions" style={{wordWrap: 'break-word', overflow: 'hidden'}}>
			<h3>Instructions</h3>
      
      <ol>
        <li>Select images (<i>Choose Files</i>)</li>
        <li>Use <i><b>right/left</b></i> keys to change images</li>
        <li>Left click and drag to segment</li>
        <li>Use menu OR <i><b>up/down</b></i> to change segment</li>
        <li>Press <b>z</b> to undo</li>
        <li>Press <b>y</b> to re-do</li>
        <li>Press <i style={{color:'red'}}>Erase</i> to turn on erase tool</li>
        <li>Press <i style={{color:'green'}}>Draw</i> to segment again</li>
        <li><i><b>Scroll</b></i> to zoom in/out</li>
        <li>Press <i><b>spacebar</b></i> to toggle b/w segment and pan</li>
        <li>Panning is done by pressing and dragging left key</li>
        <li>Press <i><b>Enter</b></i> to toggle visibility of segment</li>
        <li>Press <i style={{color:'blue'}}>Download</i> to save .png files to .zip</li>
        <li>If want to re-download, you must reset download function w/ <i style={{color:'gold'}}>Enable Additional Download</i> button under image</li>
      </ol>
      
		
		</div>
  )
}


const getPicker = (loadFiles, progressbarRef) => {
  return(
    <div>
			<h3>Choose Images</h3>
			
			<form encType="multipart/form-data">
				<input id="upload" type='file' accept="image/*" name="files[]" multiple onChange={loadFiles}/>
			</form>
			
			<div id="myProgress">
				<div id="myBar" ref={progressbarRef}></div>
			</div>

			{/* <div id="retriever">
				<button className="btn" id="download"><i className="fa fa-download"></i> Download</button>
			</div> */}
		</div>
  )
}



const getFooter = () => {
  return(
    <footer className="footer">
      <p>Copyright &copy; </p>
    </footer>
  )  
}

function readFileAsync(file) {
  // read files and return contents
  // wrap in a promise so can run async await
  // otherwise, will end up skippping reading some files
  // if they are not completed in time. 
  return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file)
  })
}

async function returnFileURLS (evt) {
    const arrayURLS = []
    for (let i = 0; i < evt.target.files.length; i++){
      let newURL = await readFileAsync(evt.target.files[i])
      let filename = evt.target.files[i].name
      filename = filename.substring(0, filename.lastIndexOf(".")) // remove suffix from filenames
      arrayURLS.push({
        filename: filename,
        url: newURL
      })
    }
    return(arrayURLS) 
}



function App() {
  const [files, setFiles] = useState([])
  const [imageIdx, setImageIdx] = useState(0)
  const [imageURL, setImageURL] = useState('')
  const [stepSize, setStepSize] = useState(0)
  const [progress, setProgress] = useState(0)
  const [numImages, setNumImages] = useState(0)
  const [downloadData, setDownloadData] = useState(false)
  const [undo, setUndo] = useState(false)
  const [redo, setRedo] = useState(false)
  const [activeSegmentIdx, setActiveSegmentIdx] = useState(1)
  const [nailSegmentIdx, setNailSegmentIdx] = useState(1)
  const [fungusSegmentIdx, setFungusSegmentIdx] = useState(2)
  const [eraseOn, setEraseOn] = useState(false)
  const [isSegmentActive, setIsSegmentActive] = useState(true)
  const [isPanActive, setIsPanActive] = useState(false)
  const [toggleOpacity, setToggleOpacity] = useState(false)
  const [isNewImages, setIsNewImages] = useState(false)
  const [percentCoverage, setPercentCoverage] = useState(0);
  

  const updateCoverage = (coverage) => {
    setPercentCoverage(coverage)

  }

  const progressbarRef = useRef(null)

  // Set the image to be based on the imageIdx
  useEffect(() => {
    setImageURL(files[imageIdx])
  }, [setImageURL, imageIdx, files]
  )

  // Update progress bar
  useEffect(() => {
    const element = progressbarRef.current;
    setProgress((imageIdx + 1) * stepSize)
    element.style.width = progress + "%"
  }, [setProgress, imageIdx, stepSize, progress])

  const loadFiles = (event) => {
    returnFileURLS(event)
      .then(response => {
        // set the files to be the newly uploaded data
        setFiles(response)
        console.log(response)
        // set the stepSize for the uploaded (first, or new) data/files
        setStepSize(100 / response.length)
        // set number of images as appropriate
        setNumImages(response.length)
        console.log(`Number of Images loaded: ${response.length}`)
        // set the image index to the beginning again
        setImageIdx(0)
      })
      .then(() => {
        setIsNewImages(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getLabelSelectors = () => {
    return (
      <div>
        <div>
          <label>
            Nail Segment Idx&emsp;
            <select 
              className="form-select form-select-lrg"
              value={nailSegmentIdx} 
              // id="segmentSelector" 
              onChange={e => changeNailSegment(e.target.value)
            }>
              <option value="1">1 </option>
              <option value="2">2 </option>
              <option value="3">3 </option>
              <option value="4">4 </option>
              <option value="5">5 </option>
            </select>
          </label>  
        </div>
        <div>
          <label>
            Fungus Segment Idx&emsp;
            <select 
              className="form-select form-select-lrg"
              value={fungusSegmentIdx} 
              // id="segmentSelector" 
              onChange={e => changeFungusSegment(e.target.value)
            }>
              <option value="1">1 </option>
              <option value="2">2 </option>
              <option value="3">3 </option>
              <option value="4">4 </option>
              <option value="5">5 </option>
            </select>
          </label>
        </div>
      </div>
    )
  }

  // Move up the image stack 
  useKeyPress(39, () => {
    if (imageIdx < (numImages - 1)){
      setImageIdx(imageIdx + 1)
      setActiveSegmentIdx(1)
    }
    console.log(`imageIdx: ${imageIdx}`)
  })

  // Move down the image stack
  useKeyPress(37, () => {
    if (imageIdx > 0){
      setImageIdx(imageIdx - 1)
      setActiveSegmentIdx(1)
    }
    console.log(`imageIdx: ${imageIdx}`)
  })

  // undo last thing on segmentations
  useKeyPress(90, () => {
    setUndo(true)
  })

  // redo segmentation edit that possibly just undid
  useKeyPress(89, () => {
    setRedo(true)
  })

  // change active segmentation using arrows
  // go down a segment number
  useKeyPress(38, () => {
    if (activeSegmentIdx > 1){
      setActiveSegmentIdx(parseInt(activeSegmentIdx) - 1) // ensure this is an int and not string + int
    } 
  })
  //go up a segment number 
  useKeyPress(40, () => {
    if (activeSegmentIdx < 5){
      setActiveSegmentIdx(parseInt(activeSegmentIdx) + 1) // ensure this is an int and not string + int
    } 
  })

  // swap between pan tool and segment tool - spacebar
  useKeyPress(32, () => {
    setIsPanActive(!isPanActive)
    setIsSegmentActive(!isSegmentActive)
  })

  // toggle the opacity of the infill of the current segment
  useKeyPress(13, () => {
    setToggleOpacity(true)
  })

  useEffect(() => {
    console.log(`activeSegmentIdx is: ${activeSegmentIdx}`)
  })

  const changeActiveSegment = (idx) => {
    setActiveSegmentIdx(parseInt(idx)) // need to convert the string from <select> into an int
    console.log(`The new active segment is: ${idx}`)
  }

  const changeNailSegment = (idx) => {
    setNailSegmentIdx(parseInt(idx))
    console.log(`Nail is now represented with id: ${idx}`)
  }

  const changeFungusSegment = (idx) => {
    setFungusSegmentIdx(parseInt(idx))
    console.log(`Fungus is now represented with id: ${idx}`)
  }

  return (
    <Router>
      
        {getHeader()}
        <div className="body-container">
          {getInstructions()}
          <div className="body-container-download" >
            {getLabelSelectors()}
          
            {getPicker(loadFiles, progressbarRef)} {/* barRef */}
            {/* Dive to give button for resetting the downloads*/}
          </div>
          
          
          <div className="body-container-image">
            <div style={{display: 'table', width: '100%', margin: '5px'}}>
              <h4 style={{display: 'table-cell'}}>{`${(numImages > 0) ? `Image Number ${imageIdx+1} / ${numImages}` : ''}  ${files[imageIdx] ? ` filename: ${files[imageIdx].filename}` : ''}`}</h4>
              <div style={{display: 'table-cell'}}>
                {(numImages > 0) 
                  ? 
                    <button
                      style={{margin: '5px'}}
                      type='button'
                      onClick={() => {setEraseOn(!eraseOn)}}
                      className={eraseOn ? 'btn btn-success': 'btn btn-danger'}
                    >
                      {eraseOn ? 'Draw' : 'Erase'}
                    </button> 
                  : ''}
              </div>
              <div >
                { numImages > 0
                  ? <label>
                    <select 
                      className="form-select form-select-lrg"
                      value={activeSegmentIdx} 
                      // id="segmentSelector" 
                      onChange={e => changeActiveSegment(e.target.value)
                    }>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                    </select>

                  </label>
                    
                  :
                    ''
                }
              </div>
              <div style={{display: 'table-cell', textAlign: 'right'}}>
                {(numImages > 0) 
                  ? <button 
                      type='button'
                      className="btn btn-primary"
                      style={{marginLeft: '5px'}}
                      onClick={() => {setDownloadData(true)}}
                    >
                      Download
                    </button> 
                  : ''
                }
              </div>
            
            </div>
            <CornerstoneViewer 
              imageURLs={files} 
              imageIdx={imageIdx} 
              downloadData={downloadData} 
              setDownloadData={setDownloadData}
              activeSegmentIdx={activeSegmentIdx}
              undo={undo}
              setUndo={setUndo}
              redo={redo}
              setRedo={setRedo}
              eraseOn={eraseOn}
              isPanActive={isPanActive}
              isSegmentActive={isSegmentActive}
              toggleOpacity={toggleOpacity}
              setToggleOpacity={setToggleOpacity}
              isNewImages={isNewImages}
              setIsNewImages={setIsNewImages}
              fungusIdx={fungusSegmentIdx}
              nailIdx={nailSegmentIdx}
              updateCoverage={updateCoverage}
              percentCoverage={percentCoverage}
            />
          </div>
          {/* {getImageContainer()} */}
        </div>
        <div>
          
        </div>
        {/* {getFooter()} */}

      
    </Router>
    
  );
}

export default App;
